import React from "react"
import styled from "styled-components"
import { Container } from "../global"
import { graphql, useStaticQuery, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const HeroHeader = (props) => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile(filter: { sourceInstanceName: { eq: "images" } }) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 1200, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  const headerImages = {
    background: { fileName: props.backgroundImage, fluid: null },
    middle: { fileName: props.middleImage, fluid: null },
    foreground: { fileName: props.foregroundImage, fluid: null },
  }

  for (var key in headerImages) {
    const imageProps = headerImages[key]
    const imageIndex = data.images.edges.findIndex(
      (y) =>
        y.node.name === imageProps.fileName.split(".").slice(0, -1).join(".")
    )
    if (imageIndex >= 0) {
      imageProps.fluid =
        data.images.edges[imageIndex].node.childImageSharp.fluid
    }
  }

  return (
    <HeaderWrapper>
      <ImagesWrapper>
        {headerImages.background && headerImages.background.fluid && (
          <BackgroundLayer
            fluid={headerImages.background.fluid}
            style={{ position: "absolute" }}
          ></BackgroundLayer>
        )}
        {headerImages.middle && headerImages.middle.fluid && (
          <MiddleLayer
            fluid={headerImages.middle.fluid}
            style={{ position: "absolute" }}
          ></MiddleLayer>
        )}
        {headerImages.foreground && headerImages.foreground.fluid && (
          <ForegroundLayer
            fluid={headerImages.foreground.fluid}
            style={{ position: "absolute" }}
          ></ForegroundLayer>
        )}
      </ImagesWrapper>
      <HeaderTextWrapper>
        <Container>
          <HeaderTextGroup>
            {props.title && (
              <>
                <h1>{props.title}</h1>
                <Separator></Separator>
              </>
            )}
            {props.subTitle && <h2>{props.subTitle}</h2>}

            {props.actionTitle && props.actionTo && (
              <ActionItem to={props.actionTo} title={props.actionTitle}>
                {props.actionTitle}
              </ActionItem>
            )}
          </HeaderTextGroup>
        </Container>
      </HeaderTextWrapper>
    </HeaderWrapper>
  )
}
export default HeroHeader

const HeaderWrapper = styled.header`
  padding: 80px 0 0;
  position: relative;
  height: calc(100vh - 80px);
`

const HeaderTextWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${(props) => props.theme.color.white.regular}96;
  overflow: hidden;
`

const ImagesWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
`
const BackgroundImageLayer = styled(BackgroundImage)`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`

const BackgroundLayer = styled(BackgroundImageLayer)`
  opacity: 0.8;
`

const ImageLayer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`

const ForegroundLayer = styled(BackgroundImageLayer)`
  animation: live-img 60s infinite;
`

const MiddleLayer = styled(BackgroundImageLayer)`
  animation: live-img 20s infinite;
`

const Separator = styled.div`
  margin: 1.875rem auto 1.875rem 0;
  height: 6px;
  width: 25%;
  background-color: ${(props) => props.theme.color.accent};
`

const HeaderTextGroup = styled.div`
  margin: 0;
  position: absolute;
  top: 30%;
  z-index: 1;
  max-width: 620px;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    max-width: 66.66%;

    h2 {
      ${(props) => props.theme.font_size.regular};
    }
  }

  h1,
  h2 {
    margin: 0;
  }
`

const ActionItem = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.color.white.regular};
  background-color: ${(props) => props.theme.color.accent};
  font-size: 1.125rem;
  line-height: 1.125rem;
  padding: 1rem 1rem 0.675rem;
  margin-top: 1.875rem;
  font-weight: bold;

  display: inline-block;
`
